<template>
  <div>
    <p class="p-3 text-lg font-bold "><span class="typcn typcn-edit"></span> Data Pelanggan</p>
     <hr class="mt-1 mb-1">
    <form  @submit.prevent="submit">
      <div class="row justify-content-center">
        <div class=" col-sm-5 p-2" style="font-size:12px;">
          <div class="shadow p-3 rounded-lg bg-white">
          <!-- INPUT -->
            <button type="button" @click="$router.push('/admin/transaksi/apotek')" class="btn btn-sm btn-dark my-3"><span class="typcn typcn-arrow-left"></span> Transaksi</button>
          <div class="sm-form">
            <input
              type="text"
              id="id"
              name="id"
              class="form-control form-control-sm"
              placeholder="id"
              v-model="datapelanggan.id"
              disabled
            />
          </div>
          <table class="table table-sm table-responsive">
         <tr>        
            <td class="capitalize">nama</td>
            <td class="pl-2 pr-2">:</td>
            <td>
                <div class="sm-form ">
                    <input type="text" id="nama" name="nama" class="form-control form-control-sm" placeholder="nama" v-model="datapelanggan.nama" >
                </div>
            </td>
        </tr>
         <tr>        
            <td class="capitalize">Jenis kelamin</td>
            <td class="pl-2 pr-2">:</td>
            <td>
               <select class='form-control' v-model="datapelanggan.jk">
               <option>Laki laki</option>
               <option>Perempuan</option>
               </select>
            </td>
        </tr>
         <tr>        
            <td class="capitalize">alamat</td>
            <td class="pl-2 pr-2">:</td>
            <td>
            <div class="sm-form">
            <textarea
            type="text"
            id="alamat"
            name="alamat"
            rows="2"
            placeholder="alamat..."
            class="form-control md-textarea"
            v-model="datapelanggan.alamat"
            ></textarea>
            </div>
            </td>
        </tr>
         <tr>        
            <td class="capitalize">Tanggal Lahir</td>
            <td class="pl-2 pr-2">:</td>
            <td>
              <div class="sm-form ">
                  <input type="date" id="tanggal_lahir" name="tanggal_lahir" class="form-control p-2 rounded-lg shadow" placeholder="tanggal_lahir" v-model="datapelanggan['tanggal_lahir']" >
              </div>
            </td>
        </tr>
         <tr>        
            <td class="capitalize">No hp</td>
            <td class="pl-2 pr-2">:</td>
            <td>
                <div class="sm-form ">
                    <input type="text" id="nohp" name="nohp" class="form-control form-control-sm" placeholder="nohp" v-model="datapelanggan.nohp" >
                </div>
            </td>
        </tr>
         <tr>        
            <td class="capitalize">point</td>
            <td class="pl-2 pr-2">:</td>
            <td>
                <div class="sm-form ">
                    <input type="number" id="point" name="point" class="form-control form-control-sm" placeholder="point" v-model="datapelanggan.point" >
                </div>
            </td>
        </tr>
         <tr>        
            <td class="capitalize">membership</td>
            <td class="pl-2 pr-2">:</td>
            <td>
              <select class='form-control' v-model="datapelanggan.membership">
              <option value='0'>NoNMembership</option>
              <option value='1'>Membership</option>
              </select>
            </td>
        </tr>
         <tr>        
            <td class="capitalize">keterangan</td>
            <td class="pl-2 pr-2">:</td>
            <td>
                <div class="sm-form">
                <textarea
                type="text"
                id="keterangan"
                name="keterangan"
                rows="2"
                placeholder="keterangan..."
                class="form-control md-textarea"
                v-model="datapelanggan.keterangan"
                ></textarea>
                </div>
            </td>
        </tr>
         </table>
          <!-- END INPUT -->
          <!-- BUTTON PART -->
          <div class="mt-3 p-3">
            <button
              type="submit"
              class="btn btn-sm"
              :class="
                btn == 'tambah'
                  ? 'btn-primary'
                  : btn == 'update'
                  ? 'btn-success'
                  : 'btn-danger'
              "
            >
              <span class="typcn typcn-info"></span>  Proses {{ btn }}
            </button>
            <div class="float-right">
              <button
                type="button"
                v-show="btn!='tambah'"
                @click="
                  btn = 'tambah';
                  datapelanggan = {};
                "
                class="btn btn-sm btn-dark"
              >
                <span class="typcn typcn-edit"></span> Tambah
              </button>
              <button
                type="button"
                @click="btn = 'delete'"
                v-show="btn!='tambah'"
                class="ml-3 btn btn-sm btn-danger"
              >
                <span class="typcn typcn-delete"></span> Delete
              </button>
            </div>
          </div>
          </div>
          <!-- END BUTTON PART -->
        </div>
        <div class=" col-sm-7 p-2" style="font-size:12px;">
          <div class="shadow bg-white rounded-lg">
            <div v-if="datanya.length>0" class="p-2">
               <div class="">
              <!-- <button type="button" @click="$router.push(`/admin/pelanggan/jadwal_minum?id=${datapelanggan.id}`)" class="btn btn-sm btn-primary  float-right" v-if="datapelanggan.id">Cek Jadwal Minum</button> -->
              <button type="button" @click="$router.push(`/admin/pelanggan/listhistory?pelanggan=${datapelanggan.id}`)" class="btn btn-sm btn-primary  float-right" v-if="datapelanggan.id">Cek Catatan History Pelanggan</button>
                <vue-json-excel :data="datanya">
                  <button type="button" class="btn btn-sm btn-dark">
                    Export <span class="typcn typcn-chart-area"></span>
                  </button>
                </vue-json-excel>
              </div>
                <Btables :tables="datanya"  :cari="['']" :hide="['']" @selected="ambil" ref="btable" />
            </div>
          </div>
        </div>
      </div>
    </form>
    <!-- TABLE PART -->
    <!-- END TABLE PART -->
     <div class="d-block d-sm-none"><br><br><br><br></div>

  </div>
</template>

<script>
// TOTURIAL 
// ganti source autophp, ganti source component btable, ganti path table sdb
import autophp from "@/plugins/autophp_kasir.js"; // ganti source nya
import Btables from "@/components/Btables.vue"; //component btable nya
let sdb = new autophp();
export default {
layout:'app_shop_admin',
  components: {
    Btables,
  },
  data() {
    return {
      selected: false,
      datapelanggan: {
        point:0,
        membership:0
      },
      btn: "tambah",
      datanya:[],
    };
  },
  methods: {
    getData(){
      sdb.collection("app_kasir_pelanggan",false).doc().select(`select * from app_kasir_pelanggan`).then(res => {
        this.datanya=res;
        this.$forceUpdate();
      });
      
    },
    ambil(data) {
      this.datapelanggan = data;
      this.btn = "update";
      this.$forceUpdate();
    },
   async submit() {
      let that=this
      if (confirm("Apakah yakin proses dilanjutkan ?")) {
        if (this.btn == "tambah") {
          //idnya letak di datapelanggan
          delete this.datapelanggan.id;
         await sdb
            .collection("app_kasir_pelanggan")
            .doc()
            .set(this.datapelanggan)
              that.getData();
            that.datapelanggan={ point:0,
        membership:0}
            // that.$nuxt.$emit('getData')

        } else if (this.btn == "update") {
         await sdb
            .collection("app_kasir_pelanggan")
            .doc()
            .set(this.datapelanggan)
              that.getData();
            that.datapelanggan={ point:0,
        membership:0}
            // that.$nuxt.$emit('getData')
        } else if (this.btn == "delete") {
         await sdb
            .collection("app_kasir_pelanggan")
            .doc(this.datapelanggan.id)
            .delete()
              that.getData();
            that.datapelanggan={ point:0,
        membership:0}
            // that.$nuxt.$emit('getData')
        }
        // this.$nuxt.$emit('getData','ok')
      }
    },
  },
  async mounted() {
    this.getData();
  },
};
</script>